[dir] .card-statistics .statistics-body {
  padding: 2rem 2.4rem !important;
}
@media (max-width: 991.98px) {
[dir] .card-statistics .card-header, [dir] .card-statistics .statistics-body {
    padding: 1.5rem !important;
}
}
[dir] .card-company-table thead th {
  border: 0;
}
[dir] .card-company-table td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
[dir] .card-company-table td .avatar {
  background-color: #f8f8f8;
}
[dir=ltr] .card-company-table td .avatar {
  margin-right: 2rem;
}
[dir=rtl] .card-company-table td .avatar {
  margin-left: 2rem;
}
[dir] .card-company-table td .avatar img {
  border-radius: 0;
}
[dir] .card-browser-states .browser-states:first-child {
  margin-top: 0;
}
[dir] .card-browser-states .browser-states:not(:first-child) {
  margin-top: 1.7rem;
}
[dir] .card-transaction .transaction-item:not(:last-child) {
  margin-bottom: 1.5rem;
}
[dir] .card-company-table td .b-avatar.badge-light-company {
  background-color: #f8f8f8;
}
[dir=ltr] .card-company-table td .b-avatar.badge-light-company {
  margin-right: 2rem;
}
[dir=rtl] .card-company-table td .b-avatar.badge-light-company {
  margin-left: 2rem;
}
.apexcharts-canvas line[stroke=transparent] {
  display: none;
}
[dir] .apexcharts-canvas .apexcharts-tooltip {
  background: #fff;
  border-color: #ebe9f1;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  font-weight: 600;
}
[dir] .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  background: #fff !important;
  border-color: #ebe9f1 !important;
}
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text-label,
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-dark .apexcharts-tooltip-text-value {
  color: #fff;
}
[dir] .apexcharts-canvas .apexcharts-xaxistooltip, [dir] .apexcharts-canvas .apexcharts-yaxistooltip {
  background: #f8f8f8;
  border-color: #ebe9f1;
}
[dir] .apexcharts-canvas .apexcharts-xaxistooltip:after, [dir] .apexcharts-canvas .apexcharts-xaxistooltip:before, [dir] .apexcharts-canvas .apexcharts-yaxistooltip:after, [dir] .apexcharts-canvas .apexcharts-yaxistooltip:before {
  border-bottom-color: #f8f8f8;
}
.apexcharts-canvas .apexcharts-text,
.apexcharts-canvas .apexcharts-tooltip-text,
.apexcharts-canvas .apexcharts-datalabel-label,
.apexcharts-canvas .apexcharts-datalabel {
  font-family: "Quicksand", sans-serif !important;
  fill: #6e6b7b;
  font-weight: 400;
  filter: none;
}
.apexcharts-canvas .apexcharts-pie-label {
  fill: white;
  filter: none;
}
.apexcharts-canvas .apexcharts-pie .apexcharts-pie-series .apexcharts-pie-area {
  stroke-width: 0;
}
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label,
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
  font-size: 1.5rem;
}
[dir] .apexcharts-canvas .apexcharts-marker {
  box-shadow: none;
}
[dir] .apexcharts-canvas .apexcharts-legend-series + .apexcharts-legend-series {
  margin-top: 0.625rem;
}
.apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  color: #6e6b7b !important;
  font-size: 1rem !important;
}
[dir=ltr] .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  margin-left: 0.5rem;
}
[dir=rtl] .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  margin-right: 0.5rem;
}
.apexcharts-canvas .apexcharts-xcrosshairs,
.apexcharts-canvas .apexcharts-ycrosshairs,
.apexcharts-canvas .apexcharts-gridline {
  stroke: #ebe9f1;
}
.apexcharts-legend.position-bottom {
  bottom: 3rem;
}
.dark-layout .apexcharts-canvas .apexcharts-xaxis-tick,
.dark-layout .apexcharts-canvas line {
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-heatmap .apexcharts-heatmap-rect {
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-radialbar .apexcharts-radialbar-track .apexcharts-radialbar-area {
  stroke: #161d31;
}
.dark-layout .apexcharts-canvas .apexcharts-radar-series polygon {
  fill: #161d31;
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-datalabels-group .apexcharts-datalabel-value {
  fill: #fff;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-tooltip {
  background: #283046;
  border-color: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  color: #fff;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title {
  background: #283046 !important;
  border-color: #3b4253 !important;
}
.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-label,
.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text-value {
  color: #fff;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-xaxistooltip, [dir] .dark-layout .apexcharts-canvas .apexcharts-yaxistooltip {
  background: #161d31;
  border-color: #3b4253;
}
[dir] .dark-layout .apexcharts-canvas .apexcharts-xaxistooltip:after, [dir] .dark-layout .apexcharts-canvas .apexcharts-xaxistooltip:before, [dir] .dark-layout .apexcharts-canvas .apexcharts-yaxistooltip:after, [dir] .dark-layout .apexcharts-canvas .apexcharts-yaxistooltip:before {
  border-bottom-color: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text,
.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-yaxistooltip-text,
.dark-layout .apexcharts-canvas .apexcharts-yaxistooltip .apexcharts-xaxistooltip-text,
.dark-layout .apexcharts-canvas .apexcharts-yaxistooltip .apexcharts-yaxistooltip-text {
  color: #fff;
}
.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text {
  color: #fff;
}
.dark-layout .apexcharts-canvas .apexcharts-yaxis-label,
.dark-layout .apexcharts-canvas .apexcharts-xaxis-label,
.dark-layout .apexcharts-canvas .apexcharts-tooltip-text,
.dark-layout .apexcharts-canvas .apexcharts-datalabel-label {
  fill: #b4b7bd;
}
.dark-layout .apexcharts-canvas .apexcharts-marker {
  stroke: #3b4253;
}
.dark-layout .apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  color: #b4b7bd !important;
}
.dark-layout .apexcharts-canvas .apexcharts-xcrosshairs,
.dark-layout .apexcharts-canvas .apexcharts-ycrosshairs,
.dark-layout .apexcharts-canvas .apexcharts-gridline {
  stroke: #3b4253;
}
.echarts {
  width: 100%;
}